var monsido_colors = (function () {
	'use strict';
	return {
		"main-1": "#299bf7",
		"secondary-1": "#f3f5f7",
		"secondary-2": "#d4dce3",
		"secondary-3": "#f5fafd",
		"secondary-4": "#555557",
		"secondary-5": "#9A9D9E",
		"secondary-6": "#E6E6E6",
		"secondary-7": "#D43F3A",
		"secondary-8": "#EFC271",
		"secondary-9": "#BA322D",
		"secondary-10": "#1C86DB",
		"secondary-11": "#004AC1",
		"secondary-12": "#D6347B",
		"secondary-13": "#A054C1",
		"secondary-14": "#4976B9",
		"secondary-15": "#FF6C00",
		"secondary-16": "#3a853a",
		"secondary-17": "#274E6E",
		"secondary-18": "#224662",
		"secondary-19": "#0F368B",
		"branding-1": "#7BEAF3",
		"branding-2": "#783CE2",
		"branding-3": "#FFCF4B",
		"branding-4": "#052942",
		"branding-5": "#34C6D0",
		"branding-6": "#FF9879",
		"branding-7": "#F4F2F0",
		"branding-8": "#CFBFAF",
	};
}());